<template>
    <div class="list pb-nav">
        <Reservation></Reservation>
        <TitleHeader :title="title" />

        <BasicList :list="idol" class="pb-res"></BasicList>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Reservation from '@/components/common/Reservation.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        BasicList,
        Reservation,
        TitleHeader
    },
    data(){
        return{
            title:'최근 부른 노래',
        }
    },
    computed:{
        idol(){
            return this.$store.state.all_songs.filter(item=>item.artist=='BTS(방탄소년단)'||'뉴진스'||'Twice'|'BLACKPINK');
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>