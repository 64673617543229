<template>
    <div class="list pb-nav">
        <Header :title="title"></Header>
        <div class="search_input position-relative bg-dark">
            <div class="d-flex category justify-content-between pb-3">
                <button class="btn rounded-circle bg-body"><i class="fas fa-microphone"></i></button>
                <button class="btn rounded-circle bg-main fs-px-14" @click="$router.push('/list/recent')">NEW</button>
                <button class="btn rounded-circle bg-body" @click="$router.push('/home')"><i class="fas fa-search"></i></button>
                <button class="btn rounded-circle bg-body" @click="$router.push('/favorite')"><i class="fas fa-star"></i></button>
                <button class="btn rounded-circle bg-body"><i class="fas fa-plus"></i></button>
            </div>
        </div>
        
        <BasicList :list="trend" class="pb-res"></BasicList>
        <Nav></Nav>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'

export default {
    components:{
        BasicList,
        Header, Nav
    },
    data(){
        return{
            title:'최신곡',
        }
    },
    computed:{
        trend(){
            return this.$store.state.all_songs;
        }
    },
}
</script>