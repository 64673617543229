<template>
    <div class="list pb-nav">
        
        <Header :title="title"></Header>
        <!-- <TitleHeader :title="title" :link="'/'"/> -->
        <div class="search_input position-relative bg-dark">
            <div class="d-flex category justify-content-between pb-3">
                <button class="btn rounded-circle bg-body"><i class="fas fa-microphone"></i></button>
                <button class="btn rounded-circle bg-body fs-px-14" @click="$router.push('/list/recent')">NEW</button>
                <button class="btn rounded-circle bg-body" @click="$router.push('/home')"><i class="fas fa-search"></i></button>
                <button class="btn rounded-circle bg-main" @click="$router.push('/favorite')"><i class="fas fa-star"></i></button>
                <button class="btn rounded-circle bg-body"><i class="fas fa-plus"></i></button>
            </div>
        </div>
        

        <BasicList :list="favorite_list" v-if="favorite_list.length>0" class="pb-res"></BasicList>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                즐겨찾는 노래가 없어요.
            </p>
        </div>
        
        <Nav></Nav>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'

export default {
    components:{
        BasicList,
        Header, Nav
    },
    data(){
        return{
            title:'즐겨찾기'
        }
    },
    computed:{
        favorite_list(){
        return this.$store.state.favorite_song
        }
    },
}
</script>
<style lang="scss" scoped>


.category {
    padding-top: 15px;
    .btn{
        width: 55px;
        height: 55px;
        font-size: 19px;
        padding: 5px !important;
        font-weight: 600;
        text-align: center;
    }
}
.search_filter{
    button{
        border: none;
        border-radius: 0;
        background-color: rgb(40,40,40);
        color: #939393;
        &.active{
            background-color: #1e1f21;
            color: #fff;
        }
        &:nth-of-type(2){
            border-right: 1px solid;
            border-left: 1px solid;
            border-color: inherit;
        }
    }
}
.search_input{
    padding: 0 15px;
    box-sizing: border-box;
}
.input-group{
    z-index: 3;
    //color: #000 !important;
    height: 50px;
    input{
        border-color: inherit;
    }
}
.input-autocomplete{
    position: absolute;
    left: 0;
    border-radius: 0 0 18px 18px;
    height: 270px;
    width: 100%;
    z-index: 2;
    padding-top: 25px;
    box-shadow: 0 0 3px black;
}
.input-group-text{
    // color: #fff;
    background-color: transparent;
    border-color: inherit;
    border: none;
    color: #FF5700;
}
.basic_list{
    height: calc(100vh - 236px);
    overflow-y: auto;
}

</style>